/** @format */

import axios from "axios";
import $ from "jquery";
import history from "./history";
import { toast } from "react-toastify";
import {
  AUDIT_TRAILS,
  DASHBOARD_DATA,
  FETCH_BILLINGS,
  FETCH_COUPONS,
  FETCH_PENDING_COMMENTS,
  FETCH_REQUESTS,
  FETCH_SINGLE_BILLINGS,
  FETCH_SINGLE_REQUEST,
  FETCH_SINGLE_SITE,
  FETCH_SITES,
  FETCH_SITES_VISITS,
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_CONVERSIONS_GRAPH,
  FETCH_SUBSCRIPTIONS_GRAPH,
  FREE_TRAILS,
  GET_EMAIL_PRICING,
  GET_EXTENSIONS,
  GET_GUIDES,
  GET_SETTINGS,
  LOADING,
  SET_PRICING,
  UNCONFIGURED_SSL,
  UNREGISTERED_SITES,
  UPDATED,
} from "../reducers/types";
import PAGES_URL from "../../routes/routes";

const apiBaseUrl = process.env.APP_BASE_URL;
const appToken = localStorage.getItem("Token");

const headers = {
  headers: {
    Authorization: appToken,
  },
};

export const fetchSites = (data, isExport) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  // console.log(data);
  const dateFilter = `${data.startDate ? `?start=${data.startDate}` : ""}${
    data.endDate ? `&end=${data.endDate}` : ""
  }`;
  axios
    .get(
      `${apiBaseUrl}/admin/sites${isExport ? "/export" : ""}?page=${
        data.page || 1
      }&limit=${data.limit || 20}&name=${data.name || ""}&user=${
        data.author || ""
      }&published=${
        data.status === "PUBLISHED"
          ? true
          : data.status === "UNPUBLISHED"
          ? false
          : ""
      }${data.startDate ? `&start=${data.startDate}` : ""}${
        data.endDate ? `&end=${data.endDate}` : ""
      }&sortVisits=${data.visit || 1}${
        data?.plan ? `&plan=${data?.plan?.toLowerCase()}` : ``
      }`,
      headers
    )
    .then((res) => {
      if (isExport) {
        dispatch({ type: LOADING, payload: null });
        const { csv } = res.data;
        const a = document.createElement("a");
        a.href = csv;
        const fileName = csv.split("/").pop();
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(csv);
        a.remove();
      } else {
        axios
          .all([
            axios.get(
              `${apiBaseUrl}/admin/sites/free-trial${dateFilter || ""}`,
              headers
            ),
            axios.get(
              `${apiBaseUrl}/admin/sites/free-trial-conversions${
                dateFilter || ""
              }`,
              headers
            ),
            axios.get(
              `${apiBaseUrl}/admin/sites/suspended${dateFilter || ""}`,
              headers
            ),
          ])
          .then((resp) => {
            const allSites = resp.map((i) => i?.data?.sites);
            dispatch({ type: LOADING, payload: null });
            dispatch({
              type: FETCH_SITES,
              payload: {
                ...res.data,
                freeTrial: allSites?.[0],
                freeTrialConversion: allSites?.[1],
                suspended: allSites?.[2],
              },
            });
          });
      }
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const getSusbcriptions = (query) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(`${apiBaseUrl}/admin/subscriptions${query || ""}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: FETCH_SUBSCRIPTIONS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const getSusbcriptionsGraph = (query, graphRoute) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/subscriptions/${graphRoute || "breakdown"}/graph${
        query || ""
      }`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      graphRoute === "breakdown" &&
        dispatch({
          type: FETCH_SUBSCRIPTIONS_GRAPH,
          payload: res.data.summary,
        });
      graphRoute === "conversions" &&
        dispatch({
          type: FETCH_SUBSCRIPTIONS_CONVERSIONS_GRAPH,
          payload: res.data.summary,
        });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const exportSusbcriptions = (query) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(`${apiBaseUrl}/admin/subscriptions/export${query || ""}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      const { csv } = res.data;
      const a = document.createElement("a");
      a.href = csv;
      const fileName = csv.split("/").pop();
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(csv);
      a.remove();
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const fetchSitesByVisit = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/sites/by-visit?sort=${data.visit || 1}&page=${
        data.page || 1
      }&limit=${data.limit || 20}${
        data.startDate ? `&start=${data.startDate}` : ""
      }${data.endDate ? `&end=${data.endDate}` : ""}`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: FETCH_SITES_VISITS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const fetchFailedRegistrations = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  // dispatch({ type: UNREGISTERED_SITES, payload: null });
  axios
    .get(
      `${apiBaseUrl}/admin/sites/unregistered?page=${data.page || 1}&limit=${
        data.limit || 20
      }${data.startDate ? `&start=${data.startDate}` : ""}${
        data.endDate ? `&end=${data.endDate}` : ""
      }&sort=${data.visit || 1}`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UNREGISTERED_SITES, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const fetchAuditTrails = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  // dispatch({ type: UNREGISTERED_SITES, payload: null });
  axios
    .get(
      `${apiBaseUrl}/admin/audit-trail?page=${data.page || 1}&limit=${
        data.limit || 20
      }${data.startDate ? `&start=${data.startDate}` : ""}${
        data.endDate ? `&end=${data.endDate}` : ""
      }&sort=${data.visit || 1}`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: AUDIT_TRAILS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const getUnconfiguredSSL = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/sites/unconfigured?page=${data.page || 1}&limit=${
        data.limit || 20
      }${data.startDate ? `&start=${data.startDate}` : ""}${
        data.endDate ? `&end=${data.endDate}` : ""
      }&sort=${data.visit || 1}`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UNCONFIGURED_SSL, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const configureSSL = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/admin/sites/${id}/domain/configure`, {}, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      console.log(res.data);
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const registerDomain = (id, data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/admin/sites/${id}/domain/register`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      console.log(res.data);
      toast.success("Domain registration successful");
      history.push(PAGES_URL.FAILED_REGISTRATIONS);
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const registerExternalDomain = (id, data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .put(`${apiBaseUrl}/admin/sites/${id}/domain/register-external`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      console.log(res.data);
      toast.success("External Domain registration successful");
      history.push(PAGES_URL.SITES);
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const fetchSingleSite = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: FETCH_SINGLE_SITE, payload: null });
  axios
    .get(`${apiBaseUrl}/admin/sites/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: FETCH_SINGLE_SITE, payload: res.data.site });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const deactivateSite = (id, data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .put(`${apiBaseUrl}/admin/sites/${id}`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      // console.log(res.data.user);
      dispatch({ type: FETCH_SINGLE_SITE, payload: res.data.site });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const fetchRequests = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/featurerequests?page=${data.page || 1}&limit=${
        data.limit || 20
      }&search=${data.name || ""}&status=${
        data.status === "ALL" ? "" : data.status
      }`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: FETCH_REQUESTS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const fetchSingleRequest = (id, rId) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({
    type: FETCH_SINGLE_REQUEST,
    payload: null,
  });
  axios
    .get(`${apiBaseUrl}/admin/featurerequests/${rId}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({
        type: FETCH_SINGLE_REQUEST,
        payload: res.data.featureRequest,
      });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const fetchPendingComments = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/featurerequests/comments/pending?limit=${
        data?.limit || 15
      }&page=${data?.page || 1}`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({
        type: FETCH_PENDING_COMMENTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const updateCommentStatus = (data, cId) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .put(`${apiBaseUrl}/admin/featurerequests/comments/${cId}`, data, headers)
    .then((res) => {
      toast.success("Status updated successfully");
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
    })
    .catch((err) => {
      // redirectPage(err);
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const createComment = (data, rId) => (dispatch, getState) => {
  const {
    data: { singleRequest },
  } = getState();

  return axios
    .post(`${apiBaseUrl}/admin/featurerequests/${rId}/comments`, data, headers)
    .then((res) => {
      toast.success("Comment added successfully");
      dispatch({
        type: FETCH_SINGLE_REQUEST,
        payload: {
          ...singleRequest,
          comments: [...singleRequest.comments, res.data.comment],
        },
      });
    })
    .catch((err) => {
      // redirectPage(err);
      toast.error(err.response.data.error.message);
    });
};

export const editComment = (data, rId, cId) => (dispatch, getState) => {
  dispatch({ type: LOADING, payload: true });
  const {
    data: { singleRequest },
  } = getState();
  const index = singleRequest.comments.map((i) => i._id).indexOf(cId);
  return axios
    .put(
      `${apiBaseUrl}/admin/featurerequests/${rId}/comments/${cId}`,
      data,
      headers
    )
    .then((res) => {
      toast.success("Comment edited successfully");
      dispatch({ type: LOADING, payload: null });
      dispatch({
        type: FETCH_SINGLE_REQUEST,
        payload: {
          ...singleRequest,
          comments: [
            ...singleRequest.comments.slice(0, index),
            res.data.comment,
            ...singleRequest.comments.slice(index + 1),
          ],
        },
      });
    })
    .catch((err) => {
      // redirectPage(err);
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const deleteComment = (rId, cId) => (dispatch, getState) => {
  const {
    data: { singleRequest },
  } = getState();
  const index = singleRequest.comments.map((i) => i._id).indexOf(cId);
  return axios
    .delete(
      `${apiBaseUrl}/admin/featurerequests/${rId}/comments/${cId}`,
      headers
    )
    .then((res) => {
      toast.success("Comment deleted successfully");
      dispatch({
        type: FETCH_SINGLE_REQUEST,
        payload: {
          ...singleRequest,
          comments: [...singleRequest.comments.filter((i) => i._id !== cId)],
        },
      });
    })
    .catch((err) => {
      // redirectPage(err);
      toast.error(err.response.data.error.message);
    });
};

export const setRequestStatus = (id, data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .put(`${apiBaseUrl}/admin/featurerequests/${id}`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      // console.log(res.data.user);
      dispatch({
        type: FETCH_SINGLE_REQUEST,
        payload: res.data.featureRequest,
      });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const deleteRequest = (id, isMultiple, ids) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .delete(
      `${apiBaseUrl}/admin/featurerequests${
        isMultiple ? `?request_ids=${ids}` : `/${id}`
      }`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      if (isMultiple) {
        dispatch({ type: UPDATED });
        toast.success("Requests Deleted Successfully");
      } else {
        history.push(PAGES_URL.REQUESTS);
        toast.success("Request Deleted Successfully");
      }
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const fetchDashboardData = () => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(`${apiBaseUrl}/admin/dashboard`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({
        type: DASHBOARD_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
    });
};

export const getCoupons = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(`${apiBaseUrl}/admin/coupons/${data || ""}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: FETCH_COUPONS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const createCoupon = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/admin/coupons`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
      toast.success("Coupon created");
      $("#exampleModal").modal("hide");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const deleteCoupon = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .delete(`${apiBaseUrl}/admin/coupons/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const createBilling = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/admin/billings`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
      toast.success("Billing created");
      $("#exampleModal").modal("hide");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const getBillings = (data, isExport) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/billings${isExport ? "/export" : ""}${data || ""}`,
      headers
    )
    .then((res) => {
      if (isExport) {
        dispatch({ type: LOADING, payload: null });
        const { csv } = res.data;
        const a = document.createElement("a");
        a.href = csv;
        const fileName = csv.split("/").pop();
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(csv);
        a.remove();
      } else {
        axios
          .get(`${apiBaseUrl}/admin/billings/summary${data || ""}`, headers)
          .then((resp) => {
            dispatch({ type: LOADING, payload: null });
            dispatch({
              type: FETCH_BILLINGS,
              payload: { ...res.data, ...resp.data },
            });
          });
      }
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const freeTrials = (data, type) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/billings/${type || "paystack"}/freetrial${
        data || ""
      }`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({
        type: FREE_TRAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const getBilling = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: FETCH_SINGLE_BILLINGS, payload: null });
  axios
    .get(`${apiBaseUrl}/admin/billings/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: FETCH_SINGLE_BILLINGS, payload: res.data.billing });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const verifyPayment = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });

  axios
    .post(`${apiBaseUrl}/admin/transaction/verify`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      toast.success("Payment verification successful");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const getExtensions = (query) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(`${apiBaseUrl}/admin/site-extensions${query || ""}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: GET_EXTENSIONS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const createExtension = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/admin/site-extensions`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
      toast.success("Site extension created");
      $("#exampleModal").modal("hide");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const editExtension = (id, data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .put(`${apiBaseUrl}/admin/site-extensions/${id}`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
      toast.success("Site extension edited");
      $(`#exampleModal${id || ""}`).modal("hide");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const deleteExtension = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .delete(`${apiBaseUrl}/admin/site-extensions/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
      toast.success(res.data.message);
      $("#exampleModal").modal("hide");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const setPricing = (data) => (dispatch) => {
  // dispatch({ type: LOADING, payload: true });
  return axios
    .post(`${apiBaseUrl}/admin/pricing`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: SET_PRICING, payload: res.data.pricing });
      toast.success("Pricing set sucessfully");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const getPricing = () => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(`${apiBaseUrl}/admin/pricing`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: SET_PRICING, payload: res.data.pricing });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

// Settings
export const getSettings = () => (dispatch) => {
  axios
    .get(`${apiBaseUrl}/admin/settings`, headers)
    .then((res) => {
      dispatch({ type: GET_SETTINGS, payload: res.data.settings });
    })
    .catch((err) => {
      toast.error(err.response && err.response.data.error.message);
      console.error(err.response && err.response.data.error.message);
    });
};

export const editSettings = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .post(`${apiBaseUrl}/admin/settings`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
      toast.success("Settings edited successfully");
      // $("#exampleModal").modal("hide");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

// User Guides
export const getGuides = () => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(`${apiBaseUrl}/admin/userguide`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: GET_GUIDES, payload: res.data.steps });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response && err.response.data.error.message);
      console.error(err.response && err.response.data.error.message);
    });
};

export const createGuide = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .post(`${apiBaseUrl}/admin/userguide`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
      toast.success("Step created successfully");
      $("#exampleModal").modal("hide");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const editGuide = (data, id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  return axios
    .put(`${apiBaseUrl}/admin/userguide/${id}`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      console.log(res.data);
      dispatch({ type: UPDATED });
      toast.success("Step edited successfully");

      $(`#editModal${id || ""}`).modal("hide");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response && err.response.data.error.message);
      console.error(err.response && err.response.data.error.message);
    });
};

export const deleteGuide = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .delete(`${apiBaseUrl}/admin/userguide/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      // console.log(res.data);
      dispatch({ type: UPDATED });
      toast.success("Step deleted successfully");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const getEmailPricing = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(
      `${apiBaseUrl}/admin/email-pricing?page=${data.page || 1}&limit=${
        data.limit || 20
      }&filter=${data.name || ""}`,
      headers
    )
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: GET_EMAIL_PRICING, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const createEmailPricing = (data) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .post(`${apiBaseUrl}/admin/email-pricing`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
      toast.success("Email Pricing created");
      $("#exampleModal").modal("hide");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const editEmailPricing = (data, id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .put(`${apiBaseUrl}/admin/email-pricing/${id}`, data, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
      toast.success("Email Pricing updated");
      $(`#exampleModal${id || ""}`).modal("hide");
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};

export const deleteEmailPricing = (id) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  axios
    .delete(`${apiBaseUrl}/admin/email-pricing/${id}`, headers)
    .then((res) => {
      dispatch({ type: LOADING, payload: null });
      dispatch({ type: UPDATED });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: null });
      toast.error(err.response.data.error.message);
      console.error(err.response.data.error.message);
    });
};
