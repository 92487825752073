/** @format */

export const LOADING = "LOADING";
export const LOGIN_USER = "LOGIN_USER";
export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_SUPER_USERS = "FETCH_SUPER_USERS";
export const FETCH_ADMINS = "FETCH_ADMINS";
export const GET_EMAIL_PRICING = "GET_EMAIL_PRICING";
export const FETCH_COUPONS = "FETCH_COUPONS";
export const FETCH_BILLINGS = "FETCH_BILLINGS";
export const FREE_TRAILS = "FREE_TRAILS";
export const FETCH_SINGLE_BILLINGS = "FETCH_SINGLE_BILLINGS";
export const FETCH_SINGLE_USER = "FETCH_SINGLE_USER";
export const FETCH_SITES = "FETCH_SITES";
export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";
export const FETCH_SUBSCRIPTIONS_GRAPH = "FETCH_SUBSCRIPTIONS_GRAPH";
export const FETCH_SUBSCRIPTIONS_CONVERSIONS_GRAPH = "FETCH_SUBSCRIPTIONS_CONVERSIONS_GRAPH";
export const FETCH_SITES_VISITS = "FETCH_SITES_VISITS";
export const UNREGISTERED_SITES = "UNREGISTERED_SITES";
export const AUDIT_TRAILS = "AUDIT_TRAILS";
export const UNCONFIGURED_SSL = "UNCONFIGURED_SSL";
export const FETCH_SINGLE_SITE = "FETCH_SINGLE_SITE";
export const FETCH_REQUESTS = "FETCH_REQUESTS";
export const FETCH_SINGLE_REQUEST = "FETCH_SINGLE_REQUEST";
export const FETCH_PENDING_COMMENTS = "FETCH_PENDING_COMMENTS";
export const LOGOUT = "LOGOUT";
export const UPDATED = "UPDATED";
export const GET_EXTENSIONS = "GET_EXTENSIONS";
export const GET_GUIDES = "GET_GUIDES";

export const SET_PRICING = "SET_PRICING";
export const GET_SETTINGS = "GET_SETTINGS";
