/** @format */

const LOGIN = "/";
const HOME = "/users";
const USERS = "/users";
const SUPER_USERS = "/super-users";
const SITES = "/sites";
const SUBSCRIPTIONS = "/subscriptions";
const UNCONFIGURED = "/unconfigured";
const SITE_VISITS = "/site-visits";
const BILLINGS = "/billings";
const FREE_TRIALS = "/free-trails";
const ADMINS = "/admins";
const EMAIL_PRICING = "/email-pricing";
const COUPONS = "/coupons";
const ADD_ADMIN = "/admins/add";
const SETTINGS = "/settings";
const EXTENSIONS = "/extensions";
const FAILED_PAYMENTS = "/failed_payments";
const FAILED_REGISTRATIONS = "/failed_registrations";
const REQUESTS = "/requests";
const PENDING_COMMENTS = "/comments/pending";
const USER_GUIDES = "/user-guide";
const AUDIT_TRAIL = "/audit-trail";

const PAGES_URL = {
  LOGIN,
  HOME,
  USERS,
  SUPER_USERS,
  SITES,
  SUBSCRIPTIONS,
  UNCONFIGURED,
  SITE_VISITS,
  BILLINGS,
  FREE_TRIALS,
  ADMINS,
  EMAIL_PRICING,
  COUPONS,
  ADD_ADMIN,
  SETTINGS,
  EXTENSIONS,
  FAILED_PAYMENTS,
  FAILED_REGISTRATIONS,
  REQUESTS,
  PENDING_COMMENTS,
  USER_GUIDES,
  AUDIT_TRAIL
};

export default PAGES_URL;
