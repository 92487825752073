/** @format */

import {
  DASHBOARD_DATA,
  FETCH_REQUESTS,
  FETCH_SINGLE_REQUEST,
  FETCH_SINGLE_SITE,
  FETCH_SITES,
  FETCH_SITES_VISITS,
  UNREGISTERED_SITES,
  UNCONFIGURED_SSL,
  FETCH_ADMINS,
  LOGOUT,
  FETCH_COUPONS,
  UPDATED,
  FETCH_BILLINGS,
  FETCH_SINGLE_BILLINGS,
  GET_EXTENSIONS,
  SET_PRICING,
  FETCH_SUBSCRIPTIONS,
  GET_GUIDES,
  GET_SETTINGS,
  GET_EMAIL_PRICING,
  AUDIT_TRAILS,
  FREE_TRAILS,
  FETCH_PENDING_COMMENTS,
  FETCH_SUBSCRIPTIONS_GRAPH,
  FETCH_SUBSCRIPTIONS_CONVERSIONS_GRAPH,
} from "./types";

const INITIAL_STATE = {
  sites: null,
  subscriptions: null,
  subscriptionsGraph: null,
  subConversionsGraph: null,
  unregisteredSites: null,
  unconfiguredSSL: null,
  extensions: null,
  siteVisits: null,
  singleSite: null,
  requests: null,
  pendingComments: null,
  singleRequest: null,
  dashboardData: null,
  admins: null,
  coupons: null,
  billings: null,
  freeTrials: null,
  billing: null,
  updated: null,
  pricing: null,
  guides: null,
  settings: null,
  emailPricing: null,
  auditTrails: null,
};

export default function dataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DASHBOARD_DATA:
      return { ...state, dashboardData: action.payload };
    case FETCH_SITES:
      return { ...state, sites: action.payload };
    case FETCH_SUBSCRIPTIONS:
      return { ...state, subscriptions: action.payload };
    case FETCH_SUBSCRIPTIONS_GRAPH:
      return { ...state, subscriptionsGraph: action.payload };
    case FETCH_SUBSCRIPTIONS_CONVERSIONS_GRAPH:
      return { ...state, subConversionsGraph: action.payload };
    case UNREGISTERED_SITES:
      return { ...state, unregisteredSites: action.payload };
    case AUDIT_TRAILS:
      return { ...state, auditTrails: action.payload };
    case UNCONFIGURED_SSL:
      return { ...state, unconfiguredSSL: action.payload };
    case FETCH_SITES_VISITS:
      return { ...state, siteVisits: action.payload };
    case FETCH_SINGLE_SITE:
      return { ...state, singleSite: action.payload };
    case FETCH_REQUESTS:
      return { ...state, requests: action.payload };
    case FETCH_PENDING_COMMENTS:
      return { ...state, pendingComments: action.payload };
    case FETCH_SINGLE_REQUEST:
      return { ...state, singleRequest: action.payload };
    case FETCH_ADMINS:
      return { ...state, admins: action.payload };
    case FETCH_COUPONS:
      return { ...state, coupons: action.payload };
    case FETCH_BILLINGS:
      return { ...state, billings: action.payload };
    case FREE_TRAILS:
      return { ...state, freeTrials: action.payload };
    case FETCH_SINGLE_BILLINGS:
      return { ...state, billing: action.payload };
    case GET_EXTENSIONS:
      return { ...state, extensions: action.payload };
    case SET_PRICING:
      return { ...state, pricing: action.payload };
    case GET_GUIDES:
      return { ...state, guides: action.payload };
    case GET_SETTINGS:
      return { ...state, settings: action.payload };
    case GET_EMAIL_PRICING:
      return { ...state, emailPricing: action.payload };
    case UPDATED:
      return { ...state, updated: !state.updated };
    case LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
